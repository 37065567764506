import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InteriorHeader from "../components/interior-header";
import NotFoundImage from "../images/404.svg"
import {Link} from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found"/>
    <InteriorHeader desktopImage={NotFoundImage} pageLocation={`page-404`} mobileImage={NotFoundImage}/>
    <div className="page-404__wrapper">
      <div className="page-404__content-wrapper">
        <h1 className={`page-404__title page-title `}><span className={`elf-green`}>Oops,</span> wrong way!</h1>
        <p className={`body-copy page-404__message`}>The page you’re looking for does not exist. Try heading back to the homepage or re-entering the URL.</p>
        <Link to={'/'} className={'button button--green page-404__cta'}>Back to homepage</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
